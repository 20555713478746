'use client';
import React, { ReactNode } from 'react';
import 'styles/App.css';
import 'styles/Contact.css';
import 'styles/MiniCalendar.css';
import 'styles/tailwind.css';
import 'styles/globals.css';
import { ChakraProvider } from '@chakra-ui/react';
import { CacheProvider } from '@chakra-ui/next-js';
import theme from '../theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { AuthProvider } from '../components/AuthProvider';
import { UserProvider } from '../contexts/UserContext';
import 'leaflet/dist/leaflet.css';

Amplify.configure(awsExports);

export default function AppWrappers({ children }: { children: ReactNode }) {
  return (
    <CacheProvider>
      <ChakraProvider theme={theme}>
        <ThemeEditorProvider>
          <AuthProvider>
            <UserProvider>
              {children}
            </UserProvider>
          </AuthProvider>
        </ThemeEditorProvider>
      </ChakraProvider>
    </CacheProvider>
  );
}
