const awsExports = ({
  "aws_cognito_identity_pool_id": "us-east-1:1af7cdfa-72db-4ba7-91cf-c5f2d8fe05ef",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_x9oQorVkK",
  "aws_user_pools_web_client_id": "13t22r9dat1onpi77fh8b456o5",
  "oauth": {
     "domain": "peple.auth.us-east-1.amazoncognito.com",
     "scope": [
         "aws.cognito.signin.user.admin",
         "email",
          "openid",
          "phone",
          "profile"
      ],
    //  "redirectSignIn": `${window.location.origin}/`,
    //  "redirectSignOut": `${window.location.origin}/`,
     "responseType": "code"
  },
  "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [],
  "aws_cognito_password_protection_settings": {
     "passwordPolicyMinLength": 8,
     "passwordPolicyCharacters": [
         "REQUIRES_LOWERCASE",
         "REQUIRES_UPPERCASE",
         "REQUIRES_NUMBERS",
         "REQUIRES_SYMBOLS"
     ]
  },
  "aws_cognito_verification_mechanisms": [
    "EMAIL"
  ],   
  "aws_appsync_graphqlEndpoint": "https://r7kdi2ku3vfttbj6wzbt5chbp4.appsync-api.us-east-1.amazonaws.com/graphql",
  "aws_appsync_region": "us-east-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_appsync_apiKey": "da2-zamdjfyi3ngndpv3iw5w5zdejy",
  "geo": {
      "amazon_location_service": {
          "region": "us-east-1",
          "maps": {
              "items": {
                  "peplemap-devpeple": {
                      "style": "VectorEsriStreets"
                  }
              },
              "default": "peplemap-devpeple"
          }
      }
  }
});

export default awsExports;