'use client';
import { Box } from '@chakra-ui/react';
import React, { ReactNode, useEffect } from 'react';
import AppWrappers from './AppWrappers';

export default function RootLayout({ children }: { children: ReactNode }) {
  useEffect(() => {
    window.document.documentElement.dir = 'ltr';
  }, []);

  return (
    <html lang="en">
      <body id={'root'}>
        <AppWrappers>{children}</AppWrappers>
      </body>
    </html>
  );
}